<template>
  <AdminCard />
</template>

<script>
import AdminCard from '@/components/AdminCard';

export default {
  components: {
    AdminCard
  }
};
</script>
